import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "IAS Academy, Mussoorie",
  address: "Mussoorie, Uttrakhand",
  client: "Lal Bahadur Shastri National Academy of Administration",
  area: "30,000 Sq. Ft.",
  project: "Auditorium cum Lecture Theatre Complex, IAS Academy, Mussoorie",
  category: "Institutions | Architecture | Institute",
  status: "Under Construction",
  backlink: "/projects/institutions/",
};

export default class Projectiasacademy extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Institutional Projects | IAS Academy, Mussoorie"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the auditorium cum lecture theatre complex commissioned by the Lal Bahadur Shastri National Academy of Administration."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
